












































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'group-cover': () => import('@/modules/community/common/components/group-cover.vue'),
    'join-dao-button': () => import('@/modules/common/components/join-dao-button.vue'),
  },
})
export default class DaoCard extends Vue {
  @Prop() dao!: any
  @Prop() newDao!: boolean
  wallet = walletStore
}
